import parsePhoneNumber from 'libphonenumber-js'

// ref: https://github.com/unicode-org/cldr/blob/release-26-0-1/common/supplemental/postalCodeData.xml
const postalCodePatterns = {
  GB: /GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\d{1,4}/,
  JE: /JE\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}/,
  GG: /GY\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}/,
  IM: /IM\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}/,
  // eslint-disable-next-line no-useless-escape
  US: /\d{5}([ \-]\d{4})?/,
  CA: /[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d/,
  DE: /\d{5}/,
  JP: /\d{3}-\d{4}/,
  FR: /\d{2}[ ]?\d{3}/,
  AU: /\d{4}/,
  IT: /\d{5}/,
  CH: /\d{4}/,
  AT: /\d{4}/,
  ES: /\d{5}/,
  NL: /\d{4}[ ]?[A-Z]{2}/,
  BE: /\d{4}/,
  DK: /\d{4}/,
  SE: /\d{3}[ ]?\d{2}/,
  NO: /\d{4}/,
  // eslint-disable-next-line no-useless-escape
  BR: /\d{5}[\-]?\d{3}/,
  // eslint-disable-next-line no-useless-escape
  PT: /\d{4}([\-]\d{3})?/,
  FI: /\d{5}/,
  AX: /22\d{3}/,
  // eslint-disable-next-line no-useless-escape
  KR: /\d{3}[\-]\d{3}/,
  CN: /\d{6}/,
  TW: /\d{3}(\d{2})?/,
  SG: /\d{6}/,
  DZ: /\d{5}/,
  AD: /AD\d{3}/,
  AR: /([A-HJ-NP-Z])?\d{4}([A-Z]{3})?/,
  AM: /(37)?\d{4}/,
  AZ: /\d{4}/,
  BH: /((1[0-2]|[2-9])\d{2})?/,
  BD: /\d{4}/,
  BB: /(BB\d{5})?/,
  BY: /\d{6}/,
  BM: /[A-Z]{2}[ ]?[A-Z0-9]{2}/,
  BA: /\d{5}/,
  IO: /BBND 1ZZ/,
  BN: /[A-Z]{2}[ ]?\d{4}/,
  BG: /\d{4}/,
  KH: /\d{5}/,
  CV: /\d{4}/,
  CL: /\d{7}/,
  CR: /\d{4,5}|\d{3}-\d{4}/,
  HR: /\d{5}/,
  CY: /\d{4}/,
  CZ: /\d{3}[ ]?\d{2}/,
  DO: /\d{5}/,
  EC: /([A-Z]\d{4}[A-Z]|(?:[A-Z]{2})?\d{6})?/,
  EG: /\d{5}/,
  EE: /\d{5}/,
  FO: /\d{3}/,
  GE: /\d{4}/,
  GR: /\d{3}[ ]?\d{2}/,
  GL: /39\d{2}/,
  GT: /\d{5}/,
  HT: /\d{4}/,
  HN: /(?:\d{5})?/,
  HU: /\d{4}/,
  IS: /\d{3}/,
  IN: /\d{6}/,
  ID: /\d{5}/,
  IL: /\d{5}/,
  JO: /\d{5}/,
  KZ: /\d{6}/,
  KE: /\d{5}/,
  KW: /\d{5}/,
  LA: /\d{5}/,
  LV: /\d{4}/,
  LB: /(\d{4}([ ]?\d{4})?)?/,
  LI: /(948[5-9])|(949[0-7])/,
  LT: /\d{5}/,
  LU: /\d{4}/,
  MK: /\d{4}/,
  MY: /\d{5}/,
  MV: /\d{5}/,
  MT: /[A-Z]{3}[ ]?\d{2,4}/,
  MU: /(\d{3}[A-Z]{2}\d{3})?/,
  MX: /\d{5}/,
  MD: /\d{4}/,
  MC: /980\d{2}/,
  MA: /\d{5}/,
  NP: /\d{5}/,
  NZ: /\d{4}/,
  NI: /((\d{4}-)?\d{3}-\d{3}(-\d{1})?)?/,
  NG: /(\d{6})?/,
  OM: /(PC )?\d{3}/,
  PK: /\d{5}/,
  PY: /\d{4}/,
  PH: /\d{4}/,
  PL: /\d{2}-\d{3}/,
  // eslint-disable-next-line no-useless-escape
  PR: /00[679]\d{2}([ \-]\d{4})?/,
  RO: /\d{6}/,
  RU: /\d{6}/,
  SM: /4789\d/,
  SA: /\d{5}/,
  SN: /\d{5}/,
  SK: /\d{3}[ ]?\d{2}/,
  SI: /\d{4}/,
  ZA: /\d{4}/,
  LK: /\d{5}/,
  TJ: /\d{6}/,
  TH: /\d{5}/,
  TN: /\d{4}/,
  TR: /\d{5}/,
  TM: /\d{6}/,
  UA: /\d{5}/,
  UY: /\d{5}/,
  UZ: /\d{6}/,
  VA: /00120/,
  VE: /\d{4}/,
  ZM: /\d{5}/,
  AS: /96799/,
  CC: /6799/,
  CK: /\d{4}/,
  RS: /\d{6}/,
  ME: /8\d{4}/,
  CS: /\d{5}/,
  YU: /\d{5}/,
  CX: /6798/,
  ET: /\d{4}/,
  FK: /FIQQ 1ZZ/,
  NF: /2899/,
  // eslint-disable-next-line no-useless-escape
  FM: /(9694[1-4])([ \-]\d{4})?/,
  GF: /9[78]3\d{2}/,
  GN: /\d{3}/,
  GP: /9[78][01]\d{2}/,
  GS: /SIQQ 1ZZ/,
  // eslint-disable-next-line no-useless-escape
  GU: /969[123]\d([ \-]\d{4})?/,
  GW: /\d{4}/,
  HM: /\d{4}/,
  IQ: /\d{5}/,
  KG: /\d{6}/,
  LR: /\d{4}/,
  LS: /\d{3}/,
  MG: /\d{3}/,
  // eslint-disable-next-line no-useless-escape
  MH: /969[67]\d([ \-]\d{4})?/,
  MN: /\d{6}/,
  // eslint-disable-next-line no-useless-escape
  MP: /9695[012]([ \-]\d{4})?/,
  MQ: /9[78]2\d{2}/,
  NC: /988\d{2}/,
  NE: /\d{4}/,
  // eslint-disable-next-line no-useless-escape
  VI: /008(([0-4]\d)|(5[01]))([ \-]\d{4})?/,
  PF: /987\d{2}/,
  PG: /\d{3}/,
  PM: /9[78]5\d{2}/,
  PN: 'PCRN 1ZZ',
  PW: '96940',
  RE: /9[78]4\d{2}/,
  SH: /(ASCN|STHL) 1ZZ/,
  SJ: /\d{4}/,
  SO: /\d{5}/,
  SZ: /[HLMS]\d{3}/,
  TC: 'TKCA 1ZZ',
  WF: /986\d{2}/,
  XK: /\d{5}/,
  YT: /976\d{2}/
}

export const validations = {
  alpha: {
    test: /^[A-Za-z]+$/i,
    msg: 'Field must be only letters'
  },
  alphaNumeric: {
    test: /^[A-Za-z0-9]+$/i,
    msg: 'Field must be only letters or numbers'
  },
  alphaNumericSpecial: {
    test: /^[A-Za-z0-9'_./#&+-\s]+$/i,
    msg: 'No special characters allowed'
  },
  currency: {
    test: /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)?$/,
    msg: 'Invalid amount, whole dollars only'
  },
  ein: {
    test: val => {
      if (val) {
        const isValid = /^\d{2}-?\d{7}$/.test(val)
        return isValid
      }
      return true
    },
    msg: 'EIN must be 9 digits'
  },
  email: {
    test: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    msg: 'Invalid email address'
  },
  lessThan100: {
    test: val => (!val ? true : val.length < 100),
    msg: 'Must be less than 100 characters'
  },
  numeric: {
    test: /^[0-9.]+$/i,
    msg: 'Must be a number'
  },
  routing: {
    match: /^\d{9}$/,
    matchMsg: 'Routing number must be 9 digits',
    test: val => {
      const d1 = parseInt(val[0], 10)
      const d2 = parseInt(val[1], 10)
      const d3 = parseInt(val[2], 10)
      const d4 = parseInt(val[3], 10)
      const d5 = parseInt(val[4], 10)
      const d6 = parseInt(val[5], 10)
      const d7 = parseInt(val[6], 10)
      const d8 = parseInt(val[7], 10)
      const d9 = parseInt(val[8], 10)

      if (
        isNaN(d1) ||
        isNaN(d2) ||
        isNaN(d3) ||
        isNaN(d4) ||
        isNaN(d5) ||
        isNaN(d6) ||
        isNaN(d7) ||
        isNaN(d8) ||
        isNaN(d9)
      ) {
        return false
      }

      if (
        (3 * (d1 + d4 + d7) + 7 * (d2 + d5 + d8) + (d3 + d6 + d9)) % 10 !=
        0
      ) {
        return false
      }

      return true
    },
    testMsg: 'Invalid routing number'
  },
  accountNumber: {
    test: /^\d{1,17}$/,
    msg: 'Invalid account number',
    testMsg: 'Invalid account number'
  },
  domain: {
    test: val => {
      if (val) {
        const isValid =
          /^(?!:\/\/)([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(\/.*)?$/i.test(
            val
          )

        return isValid
      }
      return true
    },
    msg: 'Please enter a valid domain'
  },
  url: {
    test: val => {
      if (val) {
        const isValid =
          /((https?):\/\/)(www\.)?[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(
            val
          )
        return isValid
      }
      return true
    },
    msg: 'Please enter a valid URL'
  },
  zip: {
    test: val => {
      if (val) {
        const isValid = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(val)
        return isValid
      }
      return true
    },
    msg: 'Invalid zip code'
  },
  postalCodeWithCountry: {
    test: (val, country) => {
      if (val && country) {
        const pattern = new RegExp(
          `^${String(postalCodePatterns[country]).replace(/\//g, '')}$`
        )
        return pattern.test(val)
      }
      return true
    },
    msg: 'Invalid postal code'
  },
  phone: {
    test: val => {
      if (val) {
        const isValid =
          /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(val)
        return isValid
      }
      return true
    },

    msg: 'Please enter a valid phone number'
  },
  phoneWithCountryCode: {
    test: val => {
      if (val) {
        // this validation is part of the adyen requirements
        // the phone number must have a country code and be valid
        const phone = parsePhoneNumber(val, {
          defaultCountry: 'US',
          extract: false
        })

        if (!phone) return false

        return phone && phone.isValid() && Boolean(phone.country)
      }
      return true
    },

    msg: 'Please enter a valid phone number with country code'
  },
  ssn: {
    test: val => {
      if (!val) return true

      if (val.length === 4) {
        const regex4Digits = new RegExp(/^(?!0000)\d{4}$/)
        return regex4Digits.test(val)
      }
      // ref: https://en.wikipedia.org/wiki/Social_Security_number#Valid_SSNs
      const regex = /^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/
      return regex.test(val)
    },
    msg: 'Format should be 123-45-6789 or the last 4 digits of your SSN'
  }
}

const USA_STATES = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Federated States of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Island',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
]

export const statesFull = USA_STATES.map(state => ({
  value: state,
  label: state
}))

const CA_PROVINCES = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon'
]

export const provincesFull = CA_PROVINCES.map(p => ({ value: p, label: p }))
