import { FormEvent, memo, useRef, useState } from 'react'

import Email from '@carbon/icons-react/lib/Email'
import { LoginFlow, UpdateLoginFlowBody } from '@ory/client'
import { useUserHasKratosIdentityLazyQuery } from 'graph/generated/payments/graphql-types'
import Box from 'lib/ui/Box'
import Input from 'lib/ui/Input/v2'
import { useRouter } from 'next/router'
import { validations } from 'utils/forms'
const { email: emailVal } = validations

import * as css from './styles'
import useFormSubmit from '@/hooks/useFormSubmit'
import Button from '@/ui/Button/v2'
import FormDivider from '@/ui/FormDivider'
import Node from '@/ui/Node'
import SkeletonLoader from '@/ui/SkeltonLoader'
import { NodeGroup } from '@/utils/ory/types'
const AccessYourAccountForm = ({
  loginChallenge,
  flow,
  onSubmitSSO
}: {
  loginChallenge: string
  flow: LoginFlow
  onSubmitSSO: (values: UpdateLoginFlowBody) => Promise<void>
}) => {
  const { push, reload } = useRouter()
  const emailRef = useRef(null)
  const [error, setError] = useState<{ message: string } | null>(null)
  const [getUserHasKratosIdentity] = useUserHasKratosIdentityLazyQuery()
  const { handleSubmit: handleSubmitSSO, isLoading } =
    useFormSubmit(onSubmitSSO)

  const nodes = flow?.ui?.nodes?.reduce<NodeGroup>((acc, node) => {
    acc[node.group] = [...(acc[node.group] || []), node]
    return acc
  }, {})

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()

    const email = emailRef.current?.value
    const isEmailValid = emailVal.test.test(email)

    if (!email || !isEmailValid) {
      setError({ message: emailVal.msg })
      return
    }

    try {
      if (!loginChallenge)
        return push(
          '/error?message=Login+challenge+missing+please+login+from+your+dashboard'
        )

      const hasIdentity = await getUserHasKratosIdentity({
        variables: { identifier: email }
      })
      const queryParam = `?login_challenge=${loginChallenge}&email=${email}`

      if (hasIdentity.data?.UserHasKratosIdentity) {
        return push(`/login${queryParam}`)
      }
      return push(`/registration${queryParam}`)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      return reload()
    }
  }

  if (!flow) {
    return <SkeletonLoader btnItems={1} formItems={2} />
  }

  return (
    <>
      <Box padding={0} margin={[0, 0, 1]}>
        <form css={css.containerStyle} onSubmit={handleSubmit}>
          <Input
            label='Email'
            ref={emailRef}
            name='email'
            placeholder='Email address'
            hideLabel={true}
            icon={<Email />}
            error={error}
          />
          <Button testId='continue-button' type='submit'>
            Continue {'->'}
          </Button>
        </form>
      </Box>
      <FormDivider />
      <form
        action={flow?.ui.action}
        method={flow?.ui.method}
        onSubmit={handleSubmitSSO}
      >
        {nodes?.oidc.map((node, k) => (
          <Box padding={0} margin={[0, 0, 0.5]} key={k}>
            <Node
              disabled={isLoading}
              node={node}
              value={undefined}
              dispatchSubmit={handleSubmitSSO}
            />
          </Box>
        ))}
      </form>
    </>
  )
}
export default memo(AccessYourAccountForm)
